import React from "react"
import { Box, Heading } from "@chakra-ui/core"
import { Layout } from "../../components/layout/Layout"
import { graphql } from "gatsby"
// @ts-ignore
import rehypeReact from "rehype-react"
import { TextContainer } from "../../components/containers/textContainer/TextContainer"
import { HeaderWithColor } from "../../components/headers/HeaderWithColor"
import { Seo } from "../../components/seo/Seo"

import { MainContainer } from "../../components/containers/mainContainer/MainContainer"

import { City } from "../../types/City"

import { CompareHeader } from "../../components/compare/header/CompareHeader"

import { Breadcumbs } from "../../components/navigation/breadcrumbs/Breadcumbs"
import { Region } from "../../types/Region"
import { SuggestContainer } from "../../components/suggest/SuggestContainer"
import { CVad } from "../../components/ads/CvAd"

type Props = {
  pageContext: {
    region: { frontmatter: Region; html: string }
    allRegions: { node: { frontmatter: Region } }[]
  }
  data: { cities: { edges: { node: City }[] } }
}

// @ts-ignore
const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler

const Template: React.FC<Props> = ({ pageContext, data }) => {
  const { name, urlFix, bookingId } = pageContext.region.frontmatter
  const regionCapitalized = name[0].toUpperCase() + name.slice(1)
  //   const children = useChildren(slug)

  //   const productSnippets = products.map((p, i) => {
  //     return `{
  //   "@context" : "http://schema.org",
  //   "@type" : "Product",
  //   "name" : "${p.node.name}",
  //   "image" : "https:${p.node.img.fluid.src}",
  //   "url" : "${p.node.link}",
  //   "offers" : {
  //     "@type" : "Offer",
  //     "price" : "${p.node.price}"
  //   },
  //   "aggregateRating" : {
  //     "@type" : "AggregateRating",
  //     "ratingValue" : "${p.node.rate}",
  //     "ratingCount" : "1"
  //   }
  // } ${i + 1 < products.length ? "," : ""}`
  //   })
  const suggestCities = data.cities.edges.map(({ node }) => {
    return { name: node.city, link: `/${node.region}/${node.city}` }
  })
  const regionSuggestions = pageContext.allRegions.flatMap(({ node }) => {
    if (node.frontmatter.name !== name) {
      return {
        name: node.frontmatter.name,
        link: `/${node.frontmatter.filter}`,
      }
    } else return []
  })

  return (
    <Layout>
      <Seo
        title={`Majoitus ${name} - Halvat vuokramökit ja parhaat hotellit`}
        desc={`Varaa paras majoitus kohteessa ${name}. Löydät täältä parhaimmat vuokramökit, hotellit, huoneistohotellit sekä muuta majoitusvaihtoehdot. Tutustu ja varaa sopivin majoitus.`}
      />
      <CompareHeader
        bookingId={bookingId}
        lomarengasId={urlFix}
        place={name}
        region={true}
      >
        <Breadcumbs />
      </CompareHeader>
      <CVad />

      <SuggestContainer
        title={`Tutustu tarkemmin seuraaviin kaupunkeihin kohteeessa${" "}
          ${regionCapitalized}`}
        places={suggestCities}
      />
      <HeaderWithColor>
        <Heading as="h3" color="white" p="1rem 0rem" m="0rem">
          Lisätietoja paikasta {regionCapitalized}
        </Heading>
      </HeaderWithColor>
      <MainContainer>
        <Box flex="8">
          <TextContainer>
            <Heading as="h4">{regionCapitalized}</Heading>
            <Box
              dangerouslySetInnerHTML={{ __html: pageContext.region.html }}
            ></Box>
          </TextContainer>

          <SuggestContainer
            title="Tutustu myös majoitusmahdollisuuksiin muissa maakunnissa"
            places={regionSuggestions}
          />
        </Box>

        {/* <SidebarRight>
          <FixedSidebar>
            <Heading as="h3">Sivupalkki</Heading>
          </FixedSidebar>
        </SidebarRight> */}
      </MainContainer>
      {/* <!-- JSON-LD markup generated by Google Structured Data Markup Helper. --> */}
      {/* <script type="application/ld+json">[{productSnippets}]</script> */}
    </Layout>
  )
}

export const query = graphql`
  query($filter: String!) {
    cities: allMongodbSuomimajoitusCities(
      filter: { region: { eq: $filter }, parentCity: { eq: null } }
    ) {
      edges {
        node {
          city
          parentCity
          region
        }
      }
    }

    # allMarkdownRemark(
    #   filter: { frontmatter: { type: { eq: "city" } } }
    #   sort: { fields: frontmatter___name }
    # ) {
    #   edges {
    #     node {
    #       frontmatter {
    #         filter
    #         path
    #         name
    #       }
    #     }
    #   }
    # }
  }
`

export default Template
